import React, { Component } from 'react'
import { Modal } from 'antd'
export class Detail extends Component {




    onCancel(){
       this.props.close() 
    }

    render() {
        return (
            
              <Modal
               visible={this.props.visible}
               onCancel={this.onCancel.bind(this)}
               footer={null}
               width="900px"
              >
               <h2 style={{textAlign:"center"}}>用户注册协议</h2><br/>
               <p> &nbsp;&nbsp;尊敬的客户，欢迎注册成为《智慧叉车管理平台》用户，在使用《智慧叉车管理平台》前请仔细阅读如下服务条款：
                本管理系统由“江苏华阳特安检测科技有限公司”(以下简称“本公司”)运营管理，
                并根据本协议的规定向用户提供服务。本协议双方为用户与本公司，适用于用户注册并使用本平台服务的全部功能。</p>
                <p>
                &nbsp;&nbsp; 在注册本平台之前，请务必认真阅读并充分理解本协议全部内容。点击本协议下方的“注册”按钮，并按照本平台注册程序进行注册 ，
                上述注册程序完成时即视为用户已经充分理解和同意本协议全部内容，并签署了本协议，本协议已在用户与本公司之间产生合同法律效力，
                用户使用本平台服务的同时将受到本协议的约束并应承担相应的责任和义务。
                </p>
                <h3 style={{fontWeight:'bold'}}>
                1、协议组成说明 
                </h3>
                <p>
                1.1 本协议的内容包括本协议以下全部条款以及本平台已经公示发布的及将来可能公示发布的各项规则，该等规则均为本协议不可分割的一部分，与本协议以下所列条款具有同等法律效力。
                </p>
                <p>
                1.2 本平台有权根据需要修改本协议的内容。如以下条款或本平台各项规则有任何变更，本平台将在平台上刊载公告，不再向用户作个别通知。
                经修订的相关条款和规则一经公告，即于公告规定的特定生效日期自动生效。
                请及时关注本平台关于相关条款和规则的公告，如不同意该变更，请在变更公告刊载之日起72小时内以用户在本平台注册时提供的邮箱向本平台公布的服务邮箱发送邮件或拨打客服电话表明希望终止本协议（"注册终止申请"），
                本平台确认收到邮件和电话后将与用户协商本协议终止后双方义务的履行。如用户在本条所述时限内未发送注册终止申请，则本条所述时限届满之时，
                视为用户已经同意接受该等变更，并应遵照修改后的协议履行应尽义务，经修订的相关条款和规则一经公告，即于公告规定的特定生效日期自动生效并对用户产生法律约束力。                   
                </p>
                <p>
                1.3 本协议不涉及用户与本平台的其他用户之间因网上交易而产生的法律关系及法律纠纷。但用户在此不可撤销地同意，将全面接受并履行与本平台其他用户在本平台签订的任何电子法律文本，
                 并承诺按照该法律文本享有和（或）放弃相应的权利、承担和（或）豁免相应的义务。
                </p>

                <h3 style={{fontWeight:'bold'}}>
                 2、用户注册须知 
                </h3>   
                <p> 2.1 注册成为本平台用户必须满足如下相应主体资格条件之一： </p> 
                <p>
                  1)用户必须是中国境内的居民，年龄在 18 周岁以上，且具有完全的民事权利能力及民事行为能力；
                </p>    
                <p>
                 2)用户必须是在中国境内依法成立的其他组织。如不具备上述资格，应立即停止在本平台的注册程序、停止使用本平台服务，若违反前述规定注册使用本平台服务，
                 本平台有权随时终止用户的注册进程及本平台服务，并保留追究用户法律责任的权利。                   
                </p> 
                <p>
                 2)用户必须是在中国境内依法成立的其他组织。如不具备上述资格，应立即停止在本平台的注册程序、停止使用本平台服务，若违反前述规定注册使用本平台服务，
                 本平台有权随时终止用户的注册进程及本平台服务，并保留追究用户法律责任的权利。
                 如使用他人信息和文件注册使用本平台服务或向本平台提供的信息和资料不符合上述规定，由此引起的一切责任和后果均由用户自担，本公司及本平台不因此承担任何法律责任，
                 如因此给本公司或本平台造成损失，用户应当承担赔偿责任。  
                </p>
                <h3 style={{fontWeight:'bold'}}>
                3、平台服务内容及服务费用 
                </h3>
                <p>
                3.1 本平台向用户设备安装信息采集、驾驶权限申请、维保保养作业提交、叉车控制等服务。                    
                </p>
                <p>
                3.2 用户使用本平台服务时，本公司有权收取服务费用，具体服务费用收取标准以本平台公告或者本公司与用户另行签订的相关协议为准。 
                用户在此不可撤销地承诺，将按照用户签署的相关协议约定向本公司支付服务费用，同意并授权本平台自其有关账户中直接扣划服务费用。
                用户通过本平台与其他方签订协议的，应按照签署的协议约定向其他方支付费用。    
                </p>
                <h3 style={{fontWeight:'bold'}}>
                4、用户使用规则 
                </h3>
                <p>
                4.1 用户不得利用本平台或本平台服务从事任何不符合中国法律法规或侵犯他人权益的活动。不得自行发布、转载、传送含有下列内容之一的信息，
                本平台在发现用户从事该等活动或发布信息时，有权基于本平台的独立判断直接删除用户在本平台上作出的相关信息，
                有权不经通知而立即停止用户对本平台的全部或部分功能的使用（包括但不限于中止、终止、限制用户使用本平台服务）而无需通知用户，
                亦无需承担任何责任。如因此给本公司或本平台造成损失的，应当赔偿损失。                    
                </p>
                <p>
                  4.2 在使用本平台时，用户不得违反下述规定，如违反任意一条，本平台有权基于独立判断直接删除用户在本平台上作出的相关信息，
                  有权要求用户改正或直接采取必要的措施禁止（包括但不限于中止、终止、限制用户使用本平台服务）而无需通知用户，亦无需承担任何责任。                   
                </p>
                <p>4.3 账户安全及管理： </p>
                <p>
                 4.3.1 用户在注册时向本平台提交的电子邮箱、用户名、密码及安全问题答案是用户在本平台的唯一识别信息。
                 用户注册成功后，应当妥善保管用户名和密码，并对自己的用户名、密码安全性负责，
                 不得将注册的电子邮箱、用户名、密码及安全问题答案转让、赠与或授权给第三方使用。
                 用户确认使用用户名和密码登陆本平台后在本平台的一切行为以及以用户在本平台注册时提交的电子邮箱发送邮件的行为均为用户本人操作，
                 并承担相应的法律后果，本公司及本平台不承担任何责任。
                </p>
                <p>
                   4.3.2 协助义务：用户如发现有第三人冒用或盗用用户账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知本平台，
                   要求本平台暂停相关服务，否则由此产生的一切责任由用户自担。同时，用户理解并同意本平台对其提出的请求采取行动需要合理期限，在此之前，
                   本平台对第三人使用该服务所导致的损失不承担任何责任。                    
                </p>

                <p>
                 4.3.3 账户注销：当用户决定不再使用账户时，向平台申请注销该账户，经平台审核同意后可正式注销账户。用户死亡或被宣告死亡的，其在本协议项下的各项权利义务由其继承人承担。
                 若用户丧失全部或部分民事权利能力或民事行为能力，本平台或其授权的主体有权根据有效法律文书（包括但不限于生效的法院判决等）或其法定监护人的指示处置与用户账户相关的款项。
                 强制注销用户账户的规则按此执行。
                </p>
                <p>
                4.3.4 用户同意，账户的暂停、中断或终止不代表用户责任的终止，用户仍应对使用本平台服务期间的行为承担可能的违约或损害赔偿责任，同时本平台仍可保有用户的相关信息。                   
                </p>
                <p>
                4.4 知识产权： 
                </p>
                <p>
                4.4.1 本平台的所有内容，包括但不限于文本、数据、图片、音频、视频、源代码和其他所有信息，均由本公司及本平台享有知识产权。
                未经本平台事先书面同意，不得复制、模仿、改编、传播、公布、展示或以任何其他方式侵犯本平台的知识产权。未经本公司书面同意,
                用户不得将本平台包含的资料等任何内容发布到任何其他平台或者服务器。任何未经授权对本平台内容的使用均属于违法行为，本公司有权追究法律责任。
                注册用户未经本平台的明确书面同意不许下载（除了页面缓存）或修改平台或其任何部分。
                </p>

                <h3 style={{fontWeight:'bold'}}>
                5、用户隐私保护及授权 
                </h3>
                <p>
                 5.1 用户同意本平台在业务运营中收集和储存用户信息，包括但不限于用户自行提供的资料和信息，以及本平台自行收集、取得的用户在本平台的交易记录和使用信息等。本平台收集和储存用户信息的主要目的在于提高服务的效率和质量。
                </p>
                <p>
                5.2 本平台不对外公开或第三方提供单个用户的注册资料及用户在使用网络服务时存储在本平台的非公开内容，但下列情况除外： 
                </p>
                <p>
                （1）事先获得用户的明确授权；<br/>
                （2）根据有关的法律法规要求；<br/>
                （3）按照相关政府主管部门的要求；<br/>
                 （4）为维护社会公众的利益。                    
                </p>

                <p>
                5.3 本平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站有权将用户的注册资料等提供给该第三方。
                </p>
                <p>
                 5.4 本平台按照用户在本平台上的行为自动追踪关于用户的某些资料。在不透露注册用户的隐私资料的前提下，本平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
                </p>
                <p>
                5.5 用户同意，本公司及本平台可在本平台的某些网页上使用数据收集装置。
                </p>
                <h3 style={{fontWeight:'bold'}}>
                6、责任限制 
                </h3>
                <p>
                  6.1 基于互联网的特殊性，本公司无法保证本平台的服务不会中断，对于包括但不限于本公司、本平台及相关第三方的设备、系统存在缺陷，计算机发生故障、遭到病毒、
                  黑客攻击或者发生地震、海啸等不可抗力而造成服务中断或因此给用户造成的损失，本公司不承担任何责任，有关损失由用户自担。
                </p>
                <p>
                 6.2 本公司无义务监测本平台内容。用户对于本平台披露的信息、选择使用本平台提供的服务，选择参与交易等，应当自行判断真实性和承担风险，
                 由此而产生的法律责任和后果由用户自担，本公司不承担任何责任。
                </p>
                <p>
                6.3 与本公司合作的第三方机构向用户提供的服务由第三方机构自行负责，本公司不对此等服务承担任何责任。
                </p>
                <p>
                6.4 本平台的内容可能涉及第三方所有的信息或第三方平台，该等信息或第三方平台的真实性、可靠性、有效性等由相关第三方负责，用户对该等信息或第三方平台自行判断并承担风险，与本平台和本公司无关。
                </p>
                <p>
                6.5 无论如何，本公司对用户承担的违约赔偿（如有）总额不超过向用户收取的服务费用总额。
                </p>
                <h3 style={{fontWeight:'bold'}}>
                 7、风险提示  
                </h3>
                <p>
                7.1 除按照规则运作还款风险金外（还款风险金规则以本平台最新公告为准），
                本平台不对任何用户及/或任何交易提供任何担保或条件，无论是明示、默示或法定的。本平台不能也不试图对用户发布的信息进行控制，对该等信息，本平台不承担任何形式的证明、鉴定服务。
                本平台不能完全保证平台内容的真实性、充分性、可靠性、准确性、完整性和有效性，并且无需承担任何由此引起的法律责任。用户依赖于用户的独立判断进行交易，
                用户应对其作出的判断承担全部责任。
                </p>
                <p>
                7.2 以上并不能揭示用户通过本平台进行交易的全部风险及市场的全部情形。用户在做出交易决策前，应全面了解相关交易，谨慎决策，并自行承担全部风险。
                </p>
                <h3 style={{fontWeight:'bold'}}>
                8、其他 
                </h3>
                <p>
                8.1 本协议的订立、执行、解释及争议解决均适用中华人民共和国法律（不包括香港、澳门、台湾地区）。本协议条款具有可分性，部分无效不影响其它部分效力。本平台对本协议拥有最终的解释权。       
                </p>
                <p>
                8.2 如果你是本平台注册用户，本协议条款将对用户持续有效，有效期至用户注销或者被关闭账户后。
                </p>
                <p>
                8.3 如有任何争议，应友好协商解决，协商不成，应提交本公司所在地有管辖权的法院诉讼解决。
                </p>
                <p style={{textAlign:"right"}}>
                         中国联通
                </p>
                <p style={{textAlign:"right"}}>
                       2020年7月1日
                </p>
              </Modal>  
                
            
        )
    }
}

export default Detail
