
import React from "react"

import { HomeOutlined,ApiOutlined,FilePdfOutlined,UserSwitchOutlined,OneToOneOutlined,MessageOutlined} from "@ant-design/icons"

//菜单管理
export const menus=[
    {
        path:"/dashboard",
        title:"首页",
        icon:<HomeOutlined />,
        permission:[0,1,2]
    },
    {
        path:"/message",
        title:"消息中心",
        icon:<MessageOutlined />,
        permission:[0,1,2]
	},
	{
        path:"/equipmentManagement",
        title:"设备管理",
        icon:<ApiOutlined />,
        permission:[1,2],
        children:[
            {
                path:"/equipmentManagement/startEquipment",
                title:"启动设备",
                permission:[1,2]
            },
            {
                path:"/equipmentManagement/orientationEquipment",
                title:"定位设备",
                permission:[1,2]
            }
        ]
	},
    {
        path:"/standingBook",
        icon:<OneToOneOutlined />,
        title:"叉车台账",
        permission:[0,1,2]
    },
	{
        path:"/driverManagement",
        title:"驾驶员管理",
        icon:<UserSwitchOutlined />,
        permission:[0,1,2],
        children:[
            {
                path:"/driverManagement/driverInfo",
                title:"驾驶员信息",
                permission:[0,1,2]
            },
            {
                path:"/driverManagement/applyPermission",
                title:"权限申请",
                permission:[1,2]
            },

        ]
    },
	{
        path:"/history",
        title:"历史记录",
        permission:[0,1,2],
        icon:<FilePdfOutlined />,
        children:[
            {
                path:"/history/spotCheckRecord",
                title:"点检记录",
                permission:[0,1,2]
            },
			{
                path:"/history/maintenanceRecord",
                title:"维保记录",
                permission:[0,1,2]
            },
			{
                path:"/history/workRecord",
                title:"作业记录",
                permission:[0,1,2]
			},
			{
                path:"/history/equipmentInstallRecord",
                title:"设备安装记录",
                permission:[0,1,2]
			},
			{
                path:"/history/handleOverRecord",
                title:"交接班记录",
                permission:[0,1,2]
			}
        ]
    }
    // {
    //     path:"/test",
    //     title:"测试",
    //     icon:<BgColorsOutlined />
	// },



]