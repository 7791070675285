import React, { Component } from 'react'
import
{
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,
  DatePicker,
  Select,
  Modal, message
} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined,VerticalAlignBottomOutlined,SyncOutlined,ExclamationCircleOutlined  } from "@ant-design/icons"
import { locationEquipmentList,insertEquipmentList} from "./service"
import download from "@/utils/download.js"
import { connect } from 'react-redux'

const { confirm } = Modal;


export class StandingBook extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:true,
        fold:true,
        tableData:[],
        pagination:{
            current:1,//当前页面
            pageSize: 10,
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },
        dialogVisible:false,
        detail:{},
        reflesh:false

      }
  }
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){

      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             console.log("修改成功")
             this.handleQueryInfo()
         })

      }
  }
//表单导出
  handleExport(){

    const hide = message.loading('正在导出...', 0);
    download("/api/sysLocationEquipment/downLocationEquipment","定位设备台账",this.state.tableData,()=>{
        console.log("导出成功")

        hide()
    })
  }
  //更新設別列表
  reflesh(){

     const that=this
      confirm({
        title: '你要更新设备列表吗',
        icon: <ExclamationCircleOutlined />,
        content: '点击确定会更新最新的定位设备,点击取消将不做操作',
        onOk() {
            that.setState({
                reflesh:true
              })
           insertEquipmentList({},(res)=>{
            that.setState({
                reflesh:false
              })
               if(res){
                message.success(res.message)
                that.handleQueryInfo()
               }
           })
        },
        onCancel() {},
      });
    //
  }

   componentDidMount(){

     this.handleQueryInfo()
   }
       //表单提交
   onFormFinish(values){
    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })

    }
    //查询数据
    handleQueryInfo(){
      let formValues=null


      if(this.formRef.current){
          formValues=this.formRef.current.getFieldsValue()
          console.log(formValues)
      }
      console.log(formValues)
      locationEquipmentList({
          currentPage:this.state.pagination.current,
          pageSize:this.state.pagination.pageSize,
          vehicleNo:formValues?formValues.vehicleNo:'',
          locationEquipmentNum:formValues?formValues.locationEquipmentNum:'',//设备编号
          companyName:formValues?formValues.companyName:'',
          bindState:formValues?formValues.bindState:'',
          state:formValues?formValues.equipmentState:'',
          companyId: this.props.userInfo.id,
          companyType:this.props.userInfo.companyType
      },(res)=>{
          console.log(res)

        //   console.log(JSON.stringify(res.data.list))
          if(!res){
               return
          }
          this.setState({
              loading:false,
              tableData:res.data.list.map((item,index)=>{
                  item.key=index
                  return item
              }),
              pagination:{
                  current:this.state.pagination.current,
                  pageSize:this.state.pagination.pageSize,
                  total: res.data.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
          })
      })
  }


  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }

      const { Option } = Select;

      const columns=[
        {
          title:'设备编号',
          dataIndex:"locationEquipmentNum",
          align:"center",
        },
        {
          title:'设备名称',
          dataIndex:"locationEquipmentName",
          align:"center",
        },
        {
          title:'设备开通时间',
          dataIndex:"openTime",
          align:"center",
        },
        {
          title:'设备到期时间',
          dataIndex:"expireTime",
          align:"center",
        },
        {
          title:'绑定状态',
          dataIndex:"bindStateName",
          align:"center",
        },
        {
          title:'使用单位',
          dataIndex:"companyName",
          align:"center",
        },
        {
          title:'车牌号',
          dataIndex:"vehicleNo",
          align:"center",
        },
        {
          title:'绑定时间',
          dataIndex:"bindDate",
          align:"center",
        },
        {
            title:'服务到期时间',
            dataIndex:"serviceTime",
            align:"center",
          },
          {
            title:'设备状态',
            dataIndex:"stateName",
            align:"center",
          },

      ]


      const { RangePicker } = DatePicker;

        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef}   onFinish={this.onFormFinish.bind(this)}>
                          <Form.Item label="设备编号" name="locationEquipmentNum"  className="form-item-margin">
                              <Input  placeholder="请输入设备编号"  />
                          </Form.Item>

                          <Form.Item label="使用单位" name="companyName" className="form-item-margin">
                              <Input  placeholder="请输入使用单位名称"  />
                          </Form.Item>

                          <Form.Item label="车辆牌号" name="vehicleNo"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                              <Input  placeholder="请输入车辆牌号" />
                          </Form.Item>
                          <Form.Item label="开通时间"   className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                               <RangePicker   placeholder={['开始时间','结束时间']}   />
                          </Form.Item>

                          <Form.Item label="设备到期时间"   className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                               <RangePicker   placeholder={['开始时间','结束时间']}  />
                          </Form.Item>

                          <Form.Item label="绑定状态"  name="bindState"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                                <Select  placeholder="请选择绑定状态" style={{ width: 220 }} allowClear>
                                  <Option value="0">未绑定</Option>
                                  <Option value="1">已绑定</Option>
                                </Select>
                          </Form.Item>

                          <Form.Item label="绑定时间"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                               <RangePicker   placeholder={['开始时间','结束时间']}   />
                          </Form.Item>

                          <Form.Item label="服务到期时间"   className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                               <RangePicker   placeholder={['开始时间','结束时间']}  />
                          </Form.Item>

                          <Form.Item label="设备状态"  name="equipmentState"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                                <Select  placeholder="请选择设备状态" style={{ width: 220 }}>
                                  <Option value="0">正常</Option>
                                  <Option value="1">停用</Option>
                                </Select>
                          </Form.Item>

                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>

                             <Button type="ghost" icon={<VerticalAlignBottomOutlined />}  onClick={this.handleExport.bind(this)}>导出</Button>
                             {
                              this.props.userInfo.companyType==='0'? <Button type="ghost" icon={<SyncOutlined spin={this.state.reflesh?true:false} />}  onClick={this.reflesh.bind(this)}>更新设备列表</Button>:null
                             }

                             {
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                          </Form.Item>
                      {/* <Row gutter={16} style={{width:"100%",marginTop:'20px'}} >

                      </Row> */}
                    </Form>

                    <Table columns={columns}   rowKey={record => record.id}   className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>

               </div>}
            </div>
        )
    }
}


const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(StandingBook);