import React, { Component } from 'react'
import 
{ 
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,
  Select
} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined,PlusOutlined,
  //VerticalAlignBottomOutlined
} from "@ant-design/icons"

import ForkliftInfo from "./details/ForkliftInfo"
import InsertForklift from "./details/InsertForklift"
import  { findList }  from "./service"
import { connect } from 'react-redux'
import { findDict } from "@/api/common.js"
import Edit from "./Edit"

export class StandingBook extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:true,
        fold:true,
        tableData:[],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            // total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },
        forkliftInfoVisible:false,
        editInfoVisible:false,
        forkliftInfoDetail:{},
        insertForkliftVisible:false,
        checkResultList:[]
      }
  }

  componentDidMount(){
    console.log("componentDidMount")
    findDict({sign:"forklift_check_out_result"},(res)=>{
      console.log("检验结论",res)
      this.setState({
        checkResultList:res.data
      })
    })

  //初始化查询
  this.handleQueryInfo()

}
      //表单提交
    onFormFinish(values){
      this.setState({
        pagination:{
            current:1,
            pageSize:10,
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        }
       },()=>{
           this.handleQueryInfo()
       })
    }
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){
      
      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             console.log("修改成功")
             this.handleQueryInfo()
         })
          
          
      }

  }
  //叉车档案信息
  showForkliftDetail(h){
    console.log("哈哈哈",h)
    this.setState({
      forkliftInfoDetail:h,
      forkliftInfoVisible:true
    })
  }
  showForkliftEdit(h){
       this.setState({
         editInfoVisible:true,
         forkliftInfoDetail:h
       })
  }
  //关闭弹出框
  close(e){
   console.log("receive",e)
   this.setState({
    forkliftInfoVisible:false,
    insertForkliftVisible:false,
    editInfoVisible:false
   })
   if(e){
     this.handleQueryInfo()
   }
  }
  //添加
  onInsert(){
   this.setState({
    insertForkliftVisible:true
   })
  }

      //查询数据
      handleQueryInfo(params){

        let formValues=null

        if(this.formRef.current){
            formValues=this.formRef.current.getFieldsValue()
        }
        findList({
            currentPage:this.state.pagination.current,
            pageSize:this.state.pagination.pageSize,
            vehicleNo:formValues?formValues.vehicleNo:'',
            licenseNo:formValues?formValues.licenseNo:'',
            companyName:formValues?formValues.companyName:'',
            powerWay:formValues?formValues.powerWay:'',
            checkoutResult:formValues?formValues.checkoutResult:'',
            companyId: this.props.userInfo.id,
            companyType:this.props.userInfo.companyType
        },(res)=>{
            console.log(res)
            if(!res){
                 return
            }
            this.setState({
                loading:false,
                tableData:res.data.list.map((item,index)=>{
                    item.key=index
                    return item
                }),
                pagination:{
                    current:this.state.pagination.current,
                    pageSize:this.state.pagination.pageSize,
                    total: res.data.total,
                    position:["bottomCenter"],
                    showTotal:(total)=>`共 ${total} 条`
                }
            })
           

        })
    }

  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }

      const { Option } = Select;

      const columns=[
        {
          title:'车辆牌号',
          dataIndex:"vehicleNo",
          align:"center",          
        },
        {
          title:'使用证号',
          dataIndex:"licenseNo",
          align:"center",
        },
        {
          title:'厂牌型号',
          dataIndex:"brandModel",
          align:"center",          
        },
        {
          title:'使用单位',
          dataIndex:"companyName",
          align:"center",          
        },
        {
          title:'设备地址',
          dataIndex:"companyAddr",
          align:"center",          
        },
        {
          title:'叉车类型',
          dataIndex:"powerWay",
          align:"center",          
        },
        {
          title:'下次检验日期',
          dataIndex:"dueDate",
          align:"center",          
        }, 
        {
          title:'检验结论',
          dataIndex:"checkoutResultName",
          align:"center",      
        },
        {
          title:'操作',
          align:"center", 
          render:(h)=>{
              return (<Space>
                <Button type="dashed" size="small"  onClick={()=>{this.showForkliftDetail(h)}} >叉车档案</Button>
                <Button type="primary" size="small"  onClick={()=>{this.showForkliftEdit(h)}} >编辑</Button>
                </Space>
              )
          }         
        }, 

      ]

        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}    
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef} onFinish={this.onFormFinish.bind(this)} >
                          <Form.Item label="车辆牌号" name="vehicleNo"  className="form-item-margin">
                              <Input  placeholder="请输入车牌牌号"  />
                          </Form.Item>

                          <Form.Item label="使用证号" name="licenseNo" className="form-item-margin">
                              <Input  placeholder="请输入使用证号"  />
                          </Form.Item>  
                      
                          <Form.Item label="使用单位" name="companyName"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                              <Input  placeholder="请输入使用单位" />
                          </Form.Item>

                          <Form.Item label="叉车类型" name="powerWay"  className="form-item-margin" style={{display:this.state.fold?'none':null}}>
                              <Input  placeholder="请选择叉车类型"  />
                          </Form.Item>
                       
                          <Form.Item label="检验结论" name="checkoutResult"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                               <Select placeholder="请选择检验结论" style={{width:'150px'}} allowClear>
                                  
                                    {
                                      this.state.checkResultList.map((item)=>{
                                         return <Option value={item.value} key={item.value}>{item.label}</Option>
                                      })
                                    }
                               </Select>
                          </Form.Item>
                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                             {this.props.userInfo.companyType==='0'?null:<Button type="ghost" icon={<PlusOutlined />} onClick={this.onInsert.bind(this)} >新增</Button>}
                            
                             {/* <Button type="ghost" icon={<VerticalAlignBottomOutlined />} >导出</Button> */}
                             { 
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                          </Form.Item>
                      {/* <Row gutter={16} style={{width:"100%",marginTop:'20px'}} >

                      </Row> */}
                    </Form>

                    <Table columns={columns}   rowKey={record => record.id}  className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>
                  
                     <ForkliftInfo  detail={this.state.forkliftInfoDetail} visible={this.state.forkliftInfoVisible} close={this.close.bind(this)}></ForkliftInfo>

                     <InsertForklift visible={this.state.insertForkliftVisible} close={this.close.bind(this)}></InsertForklift>

                     <Edit  detail={this.state.forkliftInfoDetail} visible={this.state.editInfoVisible} close={this.close.bind(this)}></Edit>
                     

               </div>}
            </div>
        )
    }
}


const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(StandingBook);