
import axios from '@/utils/axios'

function locationEquipmentList(param, fun){
  console.log(param)
    axios.post('/api/sysLocationEquipment/locationEquipmentList', param).then((data) => {
      fun(data)
    })
}

//更新设备列表

function insertEquipmentList(param, fun){
  console.log(param)
    axios.post('/api/sysLocationEquipment/insertEquipmentList', param).then((data) => {
      fun(data)
    })
}



//导出
function downLocationEquipment(param, fun){
  console.log(param)
    axios.post('/api/sysLocationEquipment/downLocationEquipment', param).then((data) => {
      fun(data)
    })
}


export { locationEquipmentList,insertEquipmentList,downLocationEquipment}