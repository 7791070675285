import axios from '@/utils/axios'


function findMessage(param, fun){
    axios.post('/api/sysuser/findMessage', param).then((data) => {
      fun(data)
    })
}







export { findMessage}