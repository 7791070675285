/*
 * @Descripttion:
 * @Author: chentianlin
 * @Date: 2021-02-22 13:45:10
 * @LastEditors: chentianlin
 * @LastEditTime: 2021-05-27 09:07:15
 */
import Dashboard from "../views/dashboard/index";  //主页
import BasicForm from "../views/form/BasicForm" //基础表单
import MarkDown from "../views/form/MarkDown" //编辑器
import MenuOne from "../views/menu/level-1/MenuOne" //菜单一
import MenuTwo from "../views/menu/level-1/MenuTwo" //菜单二
import News from "../views/news/News" //消息
import Error404 from "../views/error/Error404" //错误404
import Error500 from "../views/error/Error500" //错误505
import About from "../views/about/About" //关于
import StartEquipment from "@/views/equipment-management/start-equipment/index.js"//启动设备
import OrientationEquipment from "@/views/equipment-management/orientation-equipment/index.js"//定位设备
import StandingBook from "@/views/standing-book/index.js"//电梯台账
import DriverInfo from "@/views/driver-management/driver-info/index.js"//驾驶员信息
import ApplyPermission from "@/views/driver-management/apply-permission/index.js"
import SpotCheckRecord from "@/views/history/spot-check-record/index.js"//点检记录
import EquipmentInstallRecord from "@/views/history/equipment-install-record/index.js"//设备安装记录
import WorkRecord from "@/views/history/work-record/index.js"//工作记录
import MaintenanceRecord from "@/views/history/maintenance-record/index.js"//维保记录
import HandleOverRecord from "@/views/history/handle-over-record/index.js"//交接班记录
import Test from "@/views/test/Test.js"//测试
import Message from "@/views/message/index"

//路由地址
export const routes=[
    {path:'/dashboard',component:Dashboard},
    {path:'/form/basic',component:BasicForm},
    {path:'/form/markdown',component:MarkDown},
    {path:'/menu/level/submenu-1',component:MenuOne},
    {path:'/menu/level/submenu-2',component:MenuTwo},
    {path: '/news', component: News },
    {path:'/error/404',component:Error404},
    {path:'/error/500',component:Error500},
    {path:'/about',component:About},
    {path:'/test',component:Test},
    {path:"/equipmentManagement/startEquipment",component:StartEquipment},
    {path:"/equipmentManagement/orientationEquipment",component:OrientationEquipment },
    {path:"/standingBook",component:StandingBook},
    {path:"/driverManagement/driverInfo",component:DriverInfo},
    {path:"/driverManagement/applyPermission",component:ApplyPermission},
    {path:"/history/spotCheckRecord",component:SpotCheckRecord},
    {path:"/history/equipmentInstallRecord",component:EquipmentInstallRecord},
    {path:"/history/workRecord",component:WorkRecord},
    {path:"/history/maintenanceRecord",component:MaintenanceRecord},
    {path:"/history/handleOverRecord",component:HandleOverRecord},
    {path:"/message",component:Message},
]