import React, { Component } from 'react'
import 
{ 
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,
  Modal, message
} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined,ExclamationCircleOutlined } from "@ant-design/icons"
import "./index.scss"
import DetailInfo from "./details/DetailInfo"
import DetailUpdate from "./details/DetailUpdate"
import {findList,deleteUser,checkUserState} from "./service"
import { connect } from 'react-redux'
const { confirm } = Modal;
// Form,
// Input,
// Button,
// Space,
// DatePicker,
// Divider,
// Table,
// Modal,
// Col,
// Image
export class DriverInfo extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:true,
        fold:true,
        tableData:[

        ],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            // total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },

        detail:{},
        dialogUpdateVisible:false,
        dialogVisible:false
      }
  }

    //	分页、排序、筛选变化时触发
  handleTableChange(pagination, filters, sorter, extra){
      
          if(extra.action==="paginate"){
             console.log("分页中")
             console.log(pagination)
             this.setState({
              pagination:{
                  current:pagination.current,
                  pageSize:pagination.pageSize,
                  total: pagination.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
             },()=>{
                 console.log("修改成功")
                 this.handleQueryInfo()
             })

      }
  }
  componentDidMount(){
    this.handleQueryInfo()
  }
    
  //叉车档案信息
  showUpdateDetail(h){
    this.setState({
      detail:h,
      dialogUpdateVisible:true
    })
  }
  //显示详情
  showDetail(h){
    console.log(h)
    this.setState({
        detail:h,
        dialogVisible:true
    })
  }
  //关闭弹出框
  close(e){
   console.log("receive",e)
   this.setState({
    dialogVisible:false,
    dialogUpdateVisible:false
   })
   if(e){
     this.handleQueryInfo()
   }
   
  }
      //表单提交
   onFormFinish(values){ 
    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })
    }
    deleteData(h){
      
      console.log(h)

      let that=this
      confirm({
        title: '你确定删除该条记录吗',
        icon: <ExclamationCircleOutlined />,
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          console.log('OK');
           let hide= message.loading("正在删除","delete")
          deleteUser({
            id:h.id
          },(res)=>{

            if(res){
              message.success(res.message,"delete")
              that.handleQueryInfo()
            }else{
              hide()
              
            }
              
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    }
    //
    handleAudit(h){
     console.log(h)
     let that=this
     confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '请审核该驾驶人员的信息',
      okText: '通过',
      cancelText:"驳回",
      onOk() {
        let hide= message.loading("正在通过","audit")
        console.log('OK');
        checkUserState({
         id:h.id,
         state:"2"
        },(res)=>{
            console.log(res)
          if(res){
             message.success(res.message,"audit")
             that.handleQueryInfo()
          }else{
            hide()
          }
        })

      },
      onCancel() {
        let hide= message.loading("正在拒绝","audit")
        console.log('OK');
        checkUserState({
         id:h.id,
         state:"3"
        },(res)=>{
            console.log(res)
          if(res){
             message.success(res.message,"audit")
             that.handleQueryInfo()
          }else{
            hide()
          }
        })

      },
    });

    }
      //查询数据
    handleQueryInfo(){
        let formValues=null
        if(this.formRef.current){
            formValues=this.formRef.current.getFieldsValue()
            console.log(formValues)
        }
        console.log(formValues)
        findList({
            currentPage:this.state.pagination.current,
            pageSize:this.state.pagination.pageSize,
            companyName:formValues?formValues.companyName:"",
            name:formValues?formValues.name:"",
            driverNumber:formValues?formValues.driverNumber:"",
            companyId: this.props.userInfo.id,
            companyType:this.props.userInfo.companyType
        },(res)=>{
            console.log(res)
            if(!res){
                 return
            }
            this.setState({
                loading:false,
                tableData:res.data.list.map((item,index)=>{
                    item.key=index
                    return item
                }),
                pagination:{
                    current:this.state.pagination.current,
                    pageSize:this.state.pagination.pageSize,
                    total: res.data.total,
                    position:["bottomCenter"],
                    showTotal:(total)=>`共 ${total} 条`
                }
            })
        })
    }
  


  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }

      const columns=[
        {
          title:'驾驶员姓名',
          dataIndex:"userName",
          align:"center",
        },
        {
          title:'证件编号',
          dataIndex:"driverNumber",
          align:"center",          
        },        
        {
          title:'联系电话',
          dataIndex:"phone",
          align:"center",          
        },

        {
          title:'所属单位',
          dataIndex:"companyName",
          align:"center",          
        },
        {
          title:'单位地址',
          dataIndex:"companyAddr",
          align:"center",          
        },
        {
          title:'状态',
          dataIndex:"stateName",
          align:"center",          
        },
        {
          title:'添加时间',
          dataIndex:"createDate",
          align:"center",          
        },
        {
          title:'操作',
          align:"center", 
          render:(h)=>{
              return (
                <Space>
                 <Button type="dashed" size="small"  onClick={()=>{this.showDetail(h)}} >查看</Button>
                 {h.state==='1'? <Button type="dashed" size="small"  onClick={()=>{this.handleAudit(h)}} >审核</Button>:null}
                 <Button type="primary" size="small"  onClick={()=>{this.showUpdateDetail(h)}} >编辑</Button>
                 <Button type="danger" size="small"  onClick={this.deleteData.bind(this,h)} >删除</Button>
                </Space>
              )
          }         
        }   
      ]

        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}    
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef}  onFinish={this.onFormFinish.bind(this)}>


                          <Form.Item label="驾驶员" name="name"   className="form-item-margin" >
                             <Input  placeholder="请输入驾驶员名称"/>
                          </Form.Item>
                      
                       
                          <Form.Item label="证件编号" name="driverNumber"  className="form-item-margin" >
                              <Input  placeholder="请选择证件编号"  />
                          </Form.Item>

                          <Form.Item label="使用单位" name="companyName"  className="form-item-margin"  style={{display:this.state.fold?'none':null}} >
                              <Input  placeholder="请输入使用单位名称" />
                          </Form.Item>

                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                     
                             {/* <Button type="ghost" icon={<VerticalAlignBottomOutlined />} >导出</Button> */}
                             { 
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                          </Form.Item>
                      {/* <Row gutter={16} style={{width:"100%",marginTop:'20px'}} >

                      </Row> */}
                    </Form>

                    <Table columns={columns}   rowKey={record => record.id}  className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>
                  
                     <DetailInfo  detail={this.state.detail} visible={this.state.dialogVisible} close={this.close.bind(this)}></DetailInfo>

                     <DetailUpdate  detail={this.state.detail}  visible={this.state.dialogUpdateVisible} close={this.close.bind(this)}></DetailUpdate>

               </div>}
            </div>
        )
    }
}

const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(DriverInfo);
